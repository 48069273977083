<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'right',
      validator: value => ['right', 'left'].includes(value),
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    closeModal() {
      setTimeout(() => (this.open = false), 100)
    },
  },
}
</script>

<template>
  <div class="relative">
    <button
      class="z-10 relative px-3 flex items-center focus:outline-none select-none"
      @click="open = !open"
      @blur="closeModal()"
    >
      <slot name="button" />
      <Icon :name="!open ? `uil:angle-down` : `uil:angle-up`" class="ml-2" />
    </button>
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        v-show="open"
        class="absolute rounded-md whitespace-nowrap text-primary-small bg-black z-10"
        :class="placement === 'right' ? 'right-0' : 'left-0'"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>
