<script setup lang="ts">

</script>

<template>
  <footer class="bg-milky-100 h-[40px] items-center flex flex-row gap-4 px-4">
    <span class="text-primary-small">Все права защищены © ООО «Ранг Интеллект»</span>
    <NuxtLink no-prefetch to="https://rang.ai/license/" class="transition-all duration-100 text-primary-small text-blue-500 underline decoration-blue-300 cursor-pointer hover:decoration-blue-500">
      Лицензия
    </NuxtLink>
    <NuxtLink no-prefetch to="https://rang.ai/privacy/" class="transition-all duration-100 text-primary-small text-blue-500 underline decoration-blue-300 cursor-pointer hover:decoration-blue-500">
      Политика обработки персональных данных
    </NuxtLink>
  </footer>
</template>

<style scoped>

</style>
